footer {
    img {
        right:50px;
        top:0px;
    }
    p {
        color:white;
    }
    .h1 {
        font-weight:600;
    }
    ul {
        list-style-type: none;
        color:white;
        padding-left:0;
        li {
            margin-top:15px;
            margin-bottom:15px;
            line-height:1.5em;
            padding-left: 45px;
            text-indent: -45px;
        }
        span {
            display:inline-block;
            width:30px;
            height:30px;
            border-radius: 50%;
            background-color: $lightblue;
            margin-right:10px;
            text-align:center;
            i {
                text-indent: 45px;
            }
        }
        a {
            text-decoration:none;
            color:white;
            &:hover, &:active, &:focus {
                text-decoration: underline;
                color:white;
            }
        }
        &.subnav {
            margin-bottom:0;
            li {
                display:inline-block;
                border-right:1px solid white;
                line-height:1.1em;
                padding-right:10px;
                padding-left:5px;
                text-indent: 0;
                &:first-of-type {
                    padding-left:0;
                }
                &:last-of-type {
                    border-right:0;
                    padding-right:0;
                }
            }
        }
    }
}