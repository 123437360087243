//
// List group
// --------------------------------------------------


// Interactive list items

.list-group-item-action {
  transition: $list-group-action-transition;
  color: var(--#{$prefix}list-group-action-color);
  font-weight: $list-group-action-font-weight;
  &:active {
    transition: none;
  }
}


// Flush list group

.list-group-flush .list-group-item {
  padding: {
    right: 0;
    left: 0;
  }
}

.list-check ul {
  margin-left:40px;
  padding:0;
  text-indent:-40px;
  list-style: none;
  li {
    margin-bottom:25px;
    &:last-of-type {
      margin-bottom:0;
    }
    &:before {
      width:40px;
      height:30px;
      background:url('../img/icons/check-lightblue.svg');
      background-size:30px 30px;
      background-repeat: no-repeat;
      content: '';
      display:inline-block;
      vertical-align: top;
    }
  }
}