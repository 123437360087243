// 
// user.scss
// Use this to write your custom SCSS
//


.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s
}

.fade-enter,
.fade-leave-to
{
  opacity: 0
}

.fade-element {
    opacity:0;
}

.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 1s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.c-pointer {
    cursor:pointer;
}

.termsfeed-com---nb {
	background:$gray-400 !important;
	border-top:1px solid white;
	border-left:1px solid white;
}

.cc-nb-okagree, .cc-nb-reject {
	background: $primary !important;
	outline:0 !important;
	border:0 !important;
	box-shadow:none !important;
	&:active, &:focus, &:hover {
		border:0 !important;
		outline:0 !important;
		box-shadow:none !important;
	}
	&:hover {
		background: $blue !important;
	}
}

.cc-nb-reject, .cc-nb-changep {
	margin-left:5px !important;
}

.clipboard-msg {
	bottom:60px;
	background:$primary;
	padding:10px 20px;
	color:white;
	font-size:12px;
	font-weight:bold;
	min-width:115px;
}

.add-to-cart-msg {
	bottom:40px;
	background:$lightblue;
	padding:10px 20px;
	color:white;
	font-size:12px;
	font-weight:bold;
	min-width:215px;
}

.object-fit-cover {
	object-fit: cover !important;
}

.hyphenate {
	word-wrap: break-word;
	overflow-wrap: break-word;
  
  	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	hyphens: auto;
}

@media (min-width: 1585px) {
	.container {
		max-width:80% !important;
	}
}
