.fg-inner {
    padding-left:4vw;
    padding-right:4vw;
}

.fg-topbanner {
    background: rgb(25,57,113);
    background: linear-gradient(152deg, rgba(25,57,113,1) 36%, rgba(130,150,185,1) 100%);
    p {
        color:white;
        font-size:30px;
        @include media-breakpoint-down(xl) {
            font-size:20px;
        }
    }
}

.fg-slider {
    .carousel-inner {
        margin-top:0;
    }
}

.fg-powered {
    p {
        font-size:30px;
        @include media-breakpoint-down(xl) {
            font-size:20px !important;
        }
    }

    .fg-elitex-logo {
        height:26px;
        @include media-breakpoint-down(xl) {
            height:20px;
        }
    }
    a {
        text-decoration: none;
        display:block;
        &:hover {
            text-decoration: none;
        }
    }
}

.fg-text {
    @include media-breakpoint-down(xl) {
        * {
            font-size:20px;
        }
    }
    p {
        margin-bottom:20px;
        margin-top:20px;
    }
    ol {
        padding-left:0;
        list-style: inside decimal;
    }
    ul {
        li {
            margin-top:10px;
            margin-bottom:10px;
            &::marker{
                color:$primary;
            }
        }
    }
}

.fg-formular {
    h1 {
        font-size:70px;
        @include media-breakpoint-down(xl) {
            font-size:32px;
        }
    }
    .form-block {
        width:100%;
        margin-top:35px;
        margin-bottom:35px;
        display:block;
        * {
            font-size:29px;
            @include media-breakpoint-down(xl) {
                font-size:20px;
            }
        }
        label {
            display:block;
        }
        input, textarea {
            width:60%;
            @include media-breakpoint-down(xl) {
                width:100%;
            }
            background: $gray-200;
            outline:0;
            border:0;
            padding:10px 12px;
            margin-top:5px;
        }
    }
    .btn {
        border-radius:0;
        font-size:29px;
        padding-left:40px;
        padding-right:40px;
        padding-top:10px;
        padding-bottom:10px;
        margin-top:30px;
        @include media-breakpoint-down(xl) {
            font-size:20px;
        }
    }
    .fg-disclaimer {
        font-size:29px;
        @include media-breakpoint-down(xl) {
            font-size:20px;
        }
    }
}

.fg-footer {
    background: rgb(64,106,160);
    background: linear-gradient(152deg, rgba(64,106,160,1) 36%, rgba(65,105,165,1) 100%);
    li {
        display:inline-block;
        border-right:1px solid white;
        padding-right:10px;
        padding-left:5px;
        text-indent: 0;
        line-height:1em;
        margin-top:40px;
        margin-bottom:25px;
        a {
            font-size:20px;
            display:block;
            &:hover {
                text-decoration:none;
            }
        }
        &:first-of-type {
            padding-left:0;
        }
        &:last-of-type {
            border-right:0;
        }
    }
}


/* Absolute Center Spinner */
.loading {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  
  /* Transparent Overlay */
  .loading:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0, .8));
  
    background: -webkit-radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0,.8));
  }
  
  /* :not(:required) hides these rules from IE9 and below */
  .loading:not(:required) {
    /* hide "loading..." text */
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
  }
  
  .loading:not(:required):after {
    content: '';
    display: block;
    font-size: 10px;
    width: 1em;
    height: 1em;
    margin-top: -0.5em;
    -webkit-animation: spinner 150ms infinite linear;
    -moz-animation: spinner 150ms infinite linear;
    -ms-animation: spinner 150ms infinite linear;
    -o-animation: spinner 150ms infinite linear;
    animation: spinner 150ms infinite linear;
    border-radius: 0.5em;
    -webkit-box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
    box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
  }
  
  /* Animation */
  
  @-webkit-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @-moz-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @-o-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}

.accordion-button::after {
    width:25px !important;
    height:25px !important;
    background-size:25px !important;
}