//
// Dropdown
// --------------------------------------------------


// Caret

.dropdown-toggle {
  &::before, &::after {
    font: {
      family: $icons-font-family;
      size: 1.375em;
      weight: normal;
    }
    vertical-align: middle !important;
    border: none !important;
    line-height: 1;
  }
  &::after {
    margin-right: -.25rem;
    content: '\e937';
  }
  &.fs-sm::after {
    margin-top: -.0625rem !important;
  }
  &.btn:not(.btn-lg)::before,
  &.btn:not(.btn-lg)::after {
    font-size: 1.375em;
  }
}

.dropup .dropdown-toggle::after {
  content: '\e93a';
}
.dropstart .dropdown-toggle::before {
  margin-left: -.3125rem;
  content: '\e938';
}
.dropend .dropdown-toggle::after {
  margin-right: -.3125rem;
  font-size: 1.15em !important;
  content: '\e939';
}
.dropdown-toggle-split {
  &::before, &::after { margin: 0; }
}


// Dropdown menu

.dropdown-menu {
  --#{$prefix}dropdown-link-font-weight: #{$dropdown-font-weight};

  box-shadow: $dropdown-box-shadow;
  li:hover > .dropdown-item,
  .dropdown-item.show {
    color: var(--#{$prefix}dropdown-link-hover-color);
  }
  .active > .dropdown-item {
    color: var(--#{$prefix}dropdown-link-active-color);
  }
  .active > .dropdown-item,
  .dropdown-item.active {
    pointer-events: none;
  }

  &.w-100 { min-width: 100%; }
}

.dropdown-menu-dark {
  box-shadow: $dropdown-dark-box-shadow;
  li:hover > .dropdown-item,
  .dropdown-item.show {
    color: var(--#{$prefix}dropdown-link-hover-color);
  }
  .active > .dropdown-item {
    color: var(--#{$prefix}dropdown-link-active-color);
  }
}

.dropdown-item {
  font-weight: var(--#{$prefix}dropdown-link-font-weight);

  [class^='opacity-'], [class*=' opacity-'] {
    transition: opacity .2s ease-in-out;
  }

  &:hover *, &.active *, &.show * {
    opacity: 1 !important;
  }
}

li:hover > .dropdown-item *,
li.active > .dropdown-item * {
  opacity: 1 !important;
}


// Dropdown divider

.dropdown-divider {
  margin: {
    right: var(--#{$prefix}dropdown-item-padding-x);
    left: var(--#{$prefix}dropdown-item-padding-x);
  }
}
hr.dropdown-divider {
  border: 0;
  height: var(--#{$prefix}dropdown-border-width);
  background-color: var(--#{$prefix}dropdown-divider-bg);
}
.dropdown-menu-dark hr.dropdown-divider {
  border: 0;
  background-color: var(--#{$prefix}dropdown-divider-bg);
}


// Dropdown menu animation

.dropdown-menu.show {
  animation: fade-in .2s ease-in-out;
}

@keyframes fade-in  {
  from { opacity: 0; }
  to { opacity: 1; }
}

.dropdown-item {
  white-space: normal !important;
}