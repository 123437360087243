.slider-element {
    background-color: rgba($primary, .6);
    h1 {
        color:white;
    }
    &-img {
        width:100%;
        position:relative;
    }
}

.swiper-thumbnail {
    padding:1px !important;
}

.slider-element-text {
    top:-11vh;
    @include media-breakpoint-up(xxl) {
        top:30vh;
        transform:translateY(-30vh);
    }
    @include media-breakpoint-down(lg) {
        top:13vh;
        transform:translateY(-12vh);
    }
}

.carousel-inner {
    margin-top:60px;
    @include media-breakpoint-up(xxl) {
        margin-top:70px;
    }
    @include media-breakpoint-down(md) {
        h1 {
            font-size:26px;
        }
        p {
            font-size:16px;
        }
        .btn-lg {
            font-size:16px;
        }
    }
}