//
// Parallax
// --------------------------------------------------


// Background image / video parallax
// based on https://github.com/nk-o/jarallax

.jarallax {
  position: relative;
  z-index: 0;
}
.jarallax .jarallax-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
  background-repeat:no-repeat;
  background-position:center center; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}


// Disable element parallax on different breakpoints

[data-disable-parallax-down='xs'] {
  @include media-breakpoint-down(xs) {
    transform: none !important;
    opacity: 1 !important;
  }
}
[data-disable-parallax-down='sm'] {
  @include media-breakpoint-down(sm) {
    transform: none !important;
    opacity: 1 !important;
  }
}
[data-disable-parallax-down='md'] {
  @include media-breakpoint-down(md) {
    transform: none !important;
    opacity: 1 !important;
  }
}
[data-disable-parallax-down='lg'] {
  @include media-breakpoint-down(lg) {
    transform: none !important;
    opacity: 1 !important;
  }
}
[data-disable-parallax-up='sm'] {
  @include media-breakpoint-up(sm) {
    transform: none !important;
    opacity: 1 !important;
  }
}
[data-disable-parallax-up='md'] {
  @include media-breakpoint-up(md) {
    transform: none !important;
    opacity: 1 !important;
  }
}
[data-disable-parallax-up='lg'] {
  @include media-breakpoint-up(lg) {
    transform: none !important;
    opacity: 1 !important;
  }
}
[data-disable-parallax-up='xl'] {
  @include media-breakpoint-up(xl) {
    transform: none !important;
    opacity: 1 !important;
  }
}


// Cursor parallax
// based on https://github.com/wagerfield/parallax

.parallax {
  position: relative;
}
.parallax-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &:first-child { position: relative; }

  > img {
    display: block;
    width: 100%;
  }
}
